<template>
	<div>
		<el-dialog :draggable="true" width="40%" top="5vh" v-model="outerVisible" @close="Reset" :title="title">
			<div class="dialog">
				<el-form ref="ruleForm" label-width="80px">
					<el-form-item label="排序">
						<span class="xx"></span>
						<el-input clearable v-model="ruleForm.orderby" placeholder="请输入排序"></el-input>
					</el-form-item>
					<el-form-item label="名称">
						<span class="xx">*</span>
						<el-input clearable v-model="ruleForm.name" placeholder="请输入名称"></el-input>
					</el-form-item>
					<el-form-item label="上班时间">
						<span class="xx">*</span>
						<el-input clearable v-model="ruleForm.work_time" placeholder="请输入上班时间"></el-input>
					</el-form-item>
					<el-form-item label="详细地址">
						<span class="xx">*</span>
						<el-input clearable v-model="ruleForm.address" placeholder="请输入详细地址"></el-input>
					</el-form-item>
					<el-form-item label="联系电话">
						<span class="xx">*</span>
						<el-input clearable type="text" v-model="ruleForm.tel" placeholder="请输入联系电话"></el-input>
					</el-form-item>
				</el-form>
			</div>
			<template #footer>
				<div class="dialog-footer">
					<el-button size="mini" @click="outerVisible = false">取消</el-button>
					<el-button size="mini" class="dialog-btn el-white" type="primary" @click="submitForm">确定
					</el-button>
				</div>
			</template>
		</el-dialog>
	</div>
</template>

<script>
	import {
		ElMessage
	} from 'element-plus'
	import {
		request,
		api
	} from "@/api/mail";
	export default {
		data() {
			return {
				outerVisible: false,
				title: '新增',
				list: [],
				ruleForm: {
					name: '',
					orderby: 0,
					work_time: '',
					address: '',
					tel: '',
				}
			}
		},
		mounted() {},
		methods: {
			// 获取信息
			getinfo() {
				this.$http.post(api.info, {
						id: this.ruleForm.id
					})
					.then((res) => {
						if (res.code == 200) {
							this.ruleForm = res.data
							this.ruleForm.status = Number(res.data.status)
							delete this.ruleForm.create_time
							this.getsonList(res.data.typeId)
						}
					})
			},
			// 显示隐藏
			show(val, id) {
				this.title = val
				if (id) {
					this.ruleForm.id = id
					this.getinfo()
				}
				this.outerVisible = !this.outerVisible
			},
			// 清空数据
			Reset() {
				for (let key in this.ruleForm) {
					this.ruleForm[key] = ''
				}
				delete this.ruleForm.id
				delete this.ruleForm.delete_time
			},
			//新增/编辑
			submitForm() {
				if (this.ruleForm.name === '') {
					ElMessage({
						message: '请输入名称',
						type: 'warning',
					})
				} else if (this.ruleForm.work_time === '') {
					ElMessage({
						message: '请输入上班时间',
						type: 'warning',
					})
				} else if (this.ruleForm.address === '') {
					ElMessage({
						message: '请输入详细地址',
						type: 'warning',
					})
				} else if (this.ruleForm.tel === '') {
					ElMessage({
						message: '请输入联系电话',
						type: 'warning',
					})
				} else {
					if (this.title == '新增') {
						this.add()
					} else {
						this.edit()
					}
				}
			},
			// 编辑
			edit() {
				this.$http.post(api.edit, this.ruleForm)
					.then((res) => {
						if (res.code == 200) {
							ElMessage({
								type: 'success',
								message: '编辑成功',
							})
							this.$emit('SubmitForm')
							this.outerVisible = false
						}
					})
			},
			// 新增
			add() {
				this.$http.post(api.add, this.ruleForm)
					.then((res) => {
						if (res.code == 200) {
							ElMessage({
								type: 'success',
								message: '新增成功',
							})
							this.outerVisible = false
							// 刷新列表
							this.$emit('SubmitForm')
						}
					})
			}
		}
	}
</script>

<style scoped>
</style>
